import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Markdown from "components/markdown/markdown"
import Meta from "components/meta/meta"
import Layout from "components/layout/layout"
import Twitter from "components/twitter/twitter"
import { Grid, GridItem } from "components/grid/grid"

const Post = ({ data, location }) => {
  return (
    <Layout>
      <Meta
        title={data.mdx.frontmatter.title}
        image={data.mdx.frontmatter.image?.childImageSharp.fluid.src}
        description={data.mdx.excerpt}
        url={data.site.siteMetadata.url}
      />
      <Grid>
        <GridItem>
          <Markdown>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </Markdown>
          <Twitter
            title={data.mdx.frontmatter.title}
            url={`${data.site.siteMetadata.url}${location.pathname}`}
          />
        </GridItem>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    site {
      siteMetadata {
        url
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`

export default Post
