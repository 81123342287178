import React from "react"
import PropTypes from "prop-types"
import { MDXProvider } from "@mdx-js/react"

import {
  TitleLarge,
  TitleMedium,
  ParagraphMedium,
} from "components/typography/typography"
import { A, Ul, Ol, Li } from "components/typography/typographyFormatting"
import {
  Table,
  TableHead,
  TableRow,
  TableDivision,
} from "components/table/table"

const components = {
  h1: TitleLarge,
  h2: TitleMedium,
  p: ParagraphMedium,
  a: A,
  ul: Ul,
  ol: Ol,
  li: Li,
  table: Table,
  tr: TableRow,
  th: TableHead,
  td: TableDivision,
}

const Markdown = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
)

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Markdown
