import * as React from "react"
import styles from "./table.module.css"

export const Table = ({ children }) => (
  <div className={styles.tableContainer}>
    <table className={styles.table}>{children}</table>
  </div>
)

export const TableRow = ({ children }) => (
  <tr className={styles.tr}>{children}</tr>
)

export const TableHead = ({ children }) => (
  <th className={styles.th}>{children}</th>
)

export const TableDivision = ({ children }) => (
  <td className={styles.td}>{children}</td>
)
