import React from "react"
import { SecondaryLinkButton } from "../buttons/buttons"
import styles from "./twitter.module.css"

import Icon from "./icon-twitter.svg"

const Twitter = ({ title, url }) => {
  const baseUrl = "https://twitter.com/intent/tweet"
  const text = `"${title}", an article by Matt Anderson`
  const via = "mattanddesign"
  const constructedUrl = `${baseUrl}?text=${encodeURIComponent(
    text
  )}&url=${url}&via=${via}`

  return (
    <div className={styles.twitter}>
      <Icon className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.text}>
          <b>Enjoy the article?</b> I'd appreciate if you tweeted about it
        </div>
        <SecondaryLinkButton className={styles.button} href={constructedUrl}>
          Tweet
        </SecondaryLinkButton>
      </div>
    </div>
  )
}

export default Twitter
